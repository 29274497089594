import React from 'react';
import EditUserForm from './editUserForm';

const EditUserView = () => {
  return (
    <div>
      <h1>Edit User</h1>
      <EditUserForm />
    </div>
  );
};

export default EditUserView;